import { render, staticRenderFns } from "./orderDetail.vue?vue&type=template&id=c7c1adf6&scoped=true"
import script from "./orderDetail.vue?vue&type=script&lang=js"
export * from "./orderDetail.vue?vue&type=script&lang=js"
import style0 from "./orderDetail.vue?vue&type=style&index=0&id=c7c1adf6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7c1adf6",
  null
  
)

export default component.exports